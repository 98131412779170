import React from 'react'

import { Icon, IconNames } from 'ui/icon'

import useTranslation from 'lib/hooks/useTranslation'
import useHelpLink from 'lib/hooks/useHelpLink'

import { TNamespaces } from 'lib/@Types/translation'
import { useGlobalContext } from 'lib/context/global-context'

import s from './styles.module.scss'

type Categories = {
  iconName: IconNames
  title: string
  description: string
  url: string
  ns?: TNamespaces
}

const categories: Categories[] = [
  {
    iconName: 'user-circle',
    title: 't.account',
    description: 'msg.updateAccountResetPassword',
    url: '/sections/900000413366-Account',
  },
  {
    iconName: 'calendar-check-outline',
    title: 'bookings',
    description: 'msg.changeOrCancelBookingCancellationPolicy',
    url: '/sections/900000407603-Bookings',
    ns: 'booking',
  },
  {
    iconName: 'krisflyer-outline',
    title: 't.krisflyer',
    description: 'msg.queriesAboutPelagoAndKrisFlyerLoyaltyProgram',
    url: '/sections/900000413906-KrisFlyer',
  },
  {
    iconName: 'currency-converter',
    title: 'paymentsAndRefunds',
    description: 'msg.howToPayQueriesAboutPaymentOrRefund',
    url: '/sections/900000414026-Payments-Refunds',
    ns: 'payment',
  },
  {
    iconName: 'gift',
    title: 't.giftCards',
    description: 'msg.findoutHowToReddemApplyCheckGiftCards',
    url: '/sections/33649930815769-Gift-Cards',
  },
  {
    iconName: 'chat-teardrop-text',
    title: 't.feedbackAndOthers',
    description: 'msg.provideFeedbackOnProviderOrPelagoBasicTroubleshooting',
    url: '/sections/900000407883-Feedback-Others',
  },
  {
    iconName: 'handshake',
    title: 'signupAsProvider',
    description: 'msg.findOutHowToJoinPelagoAsProvider',
    url: '/sections/900000407903-Sign-up-as-a-Provider',
    ns: 'auth',
  },
]

const Category = () => {
  const { t } = useTranslation(['common', 'booking', 'payment', 'auth'])
  const { isMobileView } = useGlobalContext()
  const helpCenterUrl = useHelpLink()

  return (
    <ul className={s.categoryList}>
      {categories.map((cat) => {
        const { iconName, title, description, url, ns } = cat
        return (
          <a key={iconName} className={s.categoryItem} href={helpCenterUrl + url} rel="noopener noreferrer">
            <Icon name={iconName} size={isMobileView ? 'medium' : 'large'} />
            <div className={s.categoryContent}>
              <h3 className={isMobileView ? 'label-md' : 'label-lg'}>{t(title, { ns: ns || 'common' })}</h3>
              <span>{t(description, { ns: ns || 'common' })}</span>
            </div>
          </a>
        )
      })}
    </ul>
  )
}

export default Category
