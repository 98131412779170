import { useRouter } from 'next/router'

import { FALLBACK_LOCALE, HELPCENTER_BASE } from 'lib/constants'

const HELP_LINK_LOCALES_SUPPORT = new Map([
  ['id-ID', 'id-id'],
  [FALLBACK_LOCALE, 'en-us'],
])

const useHelpLink = (): string => {
  const { locale } = useRouter()
  const helpLinkLocale =
    locale && HELP_LINK_LOCALES_SUPPORT.has(locale)
      ? HELP_LINK_LOCALES_SUPPORT.get(locale)
      : HELP_LINK_LOCALES_SUPPORT.get(FALLBACK_LOCALE)

  const helpCenterUrl = HELPCENTER_BASE + helpLinkLocale

  return helpCenterUrl
}

export default useHelpLink
