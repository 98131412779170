import React, { useState, useRef } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { HELP_CENTER_QUERY } from 'lib/constants'

import s from './styles.module.scss'

const SearchBar = () => {
  const { t } = useTranslation('common')
  const { isMobileView } = useGlobalContext()
  const [value, setValue] = useState<string>('')
  const placeholder = t('msg.searchForTopic')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const doSearch = () => {
    if (window?.location?.href && inputRef?.current) {
      window.location.href = HELP_CENTER_QUERY + inputRef.current.value
    }
  }

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      window.location.href = HELP_CENTER_QUERY + e.target.value
    }
  }

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  const onClear = () => {
    setValue('')
  }

  return (
    <div className={s.searchBar}>
      <input
        ref={(ref) => (inputRef.current = ref)}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder={placeholder}
        className={isMobileView ? 'pl-4' : 'pl-6'}
      />
      {value && (
        <Button
          iconName="delete-x"
          shape="circle"
          size={isMobileView ? 'small' : 'medium'}
          variant="tertiary-grey"
          className={cn(s.clearButton, { ['mr-2']: value })}
          onClick={onClear}
        />
      )}
      <button className={s.submitButton} onClick={doSearch}>
        <Icon name="magnifying-glass" size="medium" />
      </button>
    </div>
  )
}

export default SearchBar
